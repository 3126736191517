.contentCard {
  display: flex;
  flex-direction: column;
  gap: var(--ds-spacing-xs);
  max-width: 756px; /* max width when only two cards are shown */
  padding: 0 var(--ds-spacing-5xs); /* padding to allow for 2px of focus ring style in the carousel */

  &.showTitle h3 {
    display: block;
  }

  &.showDescription p {
    /* In order to add ellipsis when the text overflows, we use -webkit-box */
    display: -webkit-box;
  }

  &.showCta div:last-child {
    display: flex;
  }
}

.cardLinkWrapper,
.cardLinkWrapper:hover,
.cardLinkWrapper:visited,
.cardLinkWrapper:active {
  text-decoration: none;
  color: inherit;
}

.imageLinkWrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
}

.imageWrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  position: relative;

  & > .videoOverlayButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  & > :nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.standardImage {
  display: grid;
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  & img {
    width: 100%;
    height: auto;
    aspect-ratio: 1.4 / 1;
    user-select: none;
    pointer-events: none;
  }
}

.squareImage {
  display: grid;
  grid-column: 1 / 3;
  grid-row: 1 / 3;

  & img {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    user-select: none;
    pointer-events: none;
  }
}

.logos {
  z-index: 1;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  display: flex;
  justify-content: flex-end;
  gap: var(--ds-spacing-3xs);
  padding: var(--ds-spacing-3xs);

  & img {
    aspect-ratio: unset;
    width: var(--ds-size-4xl);
    user-select: none;
    pointer-events: none;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: var(--ds-spacing-xs);

  &.left {
    align-items: flex-start;
    text-align: left;
  }

  &.center {
    justify-content: space-between;
    text-align: center;
  }
}

/* By default, we do not display the element, we only display it if there is content available  */
.standardTitle,
.largeTitle {
  display: none;
  width: 100%;
  margin: 0;
  margin-bottom: var(--ds-spacing-3xs);
}

.description {
  display: none;
  width: 100%;
  margin: 0;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  overflow: hidden;

  @media (--ds-breakpoint-lg) {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
}

.cta {
  display: none;
  width: 100%;
  height: 44px; /* height of the medium skrøll button*/
  gap: var(--ds-spacing-2xs);
  margin: 0;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }
}
