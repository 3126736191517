.carousel {
  display: none;

  @media (--ds-breakpoint-sm) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1600px;
    background-color: var(--carousel-background-color);
    color: var(--text-color);
    padding: var(--ds-spacing-lg);
  }
}

.verticalMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--ds-spacing-xs);
  padding: var(--ds-spacing-xs);

  @media (--ds-breakpoint-sm) {
    display: none;
  }
}

.mobileHeadingWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  padding: var(--ds-spacing-5xs) 0;
}

.title {
  margin: 0;
}
